<template>
  <div>
    <app-header class="mb-3" />
    <div class="container">
      <user-view />
    </div>
    <app-footer />
  </div>
</template>

<script>
import UserView from './UsersView.vue'
import AppFooter from '../../../pages/landing/AppFooter.vue'
import AppHeader from '../../../pages/landing/AppHeader.vue'

export default {
  components: {
    UserView,
    AppFooter,
    AppHeader,
  },
  data() {
   return {
   }
  },
}
</script>
